import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import RideTable from '../../Components/Rides';
import { getRideById, clearRides, getRideLocations, getRefundForRide } from '../../state/rides';
import { getVehicleRides } from '../../state/vehicle_rides';
import { getFeatures } from '../../state/zones';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from 'reactjs-popup';
import Loader from '../../Components/Loader';
import Map from '../../Components/ZoneMap';
import ScooterRides from '../../Components/ScooterRides';
import RideDetails from '../../Components/RideDetails';
import { INVOICE, SCOOTER_RIDE } from '../../util/constants';
import { MAP } from 'react-google-maps/lib/constants';
import { getTimeInterval, penniesToDollars } from '../../util/helper';

import closeButtonImage from '../../images/close.png';

// TODO: Add controls to show/hide restricted areas, parking spots

class Ride extends Component {
  constructor(props) {
    super(props);
    let rides;
    if (this.props.location && this.props.location.state && this.props.location.state.rides) {
      rides = this.props.location.state.rides;
    }
    this.state = {
      feedback: '',
      feedbackColor: 'red',
      disabled: false,
      rides,
      loaded: true,
      showRidesTable: false,
      ride_id: '',
      name: '',
      open: false,
      vehicleRidesTableData: [],
      selectedVehicleModel: '',
      perMinuteCharge: '',
      baseCharge: '',
      propType: '',
      previousRefundedAmount: '',
    };
    this.openInvoicePopup = this.openInvoicePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const rideData = this.props.location;
    if (rideData.data && rideData.data.selectedRide.id) {
      this.onSubmitRide(rideData.data.selectedRide.id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { rides } = props;
    const newState = {
      ...state,
      rides,
    };
    return newState;
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmitRide = (submitVal) => {
    submitVal.preventDefault();
    this.setState({ feedback: '', disabled: false, loaded: false });
    let ride_id;
    if (typeof submitVal === 'object' && submitVal !== null) {
      if (this.state.ride_id.length < 8) {
        alert('Ride Id must be at least 8 characters');
        this.setState({
          loaded: true,
        });
        return;
      }
      ride_id = this.state.ride_id;
    } else {
      ride_id = submitVal;
      this.setState({
        isFromRedirect: false,
        ride_id: ride_id,
      });
    }
    const params = {};
    params.ride_id = ride_id;
    this.props.actions
      .getRideById(params)
      .then((rides) => {
        this.setState({
          rides,
          disabled: false,
          loaded: true,
          showRidesTable: true,
        });
      })
      .catch((error) => {
        this.setState({
          feedback: error.message || 'An unknown error occurred',
          feedbackColor: 'red',
          disabled: false,
          loaded: true,
        });
      });
  };

  onClickReset = (event) => {
    event.preventDefault();
    this.props.actions.clearRides();
    this.setState({
      showRidesTable: false,
      ride_id: '',
    });
  };

  scooterClick = (scooter) => {
    const vehicle = {
      vehicle: {
        name: scooter,
      },
    };
    this.props.history.push({
      pathname: '/vehicles/edit',
      data: { selectedScooter: vehicle },
    });
  };

  openInvoicePopup = (ride) => {
    this.setState({ loaded: false });
    const payment_status = ride.payment_status;
    const selectedVehicleModel = ride.vehicle.model_name;
    const perMinuteCharge = ride.price.per_minute_price;
    const baseCharge = ride.price.base_price;
    const ride_id = ride.id;
    let refundedAmount = '';
    //if ride is partially refunded, get the amount refunded to display on invoice popup
    if (payment_status === 'partially_refunded') {
      this.props.actions.getRefundForRide(ride_id).then(() => {
        refundedAmount = penniesToDollars(((this.props.rides || {}).refund || {}).refund_amount);
        this.setState({
          popupType: INVOICE,
          selectedVehicleModel: selectedVehicleModel,
          perMinuteCharge: perMinuteCharge,
          baseCharge: baseCharge,
          selectedRide: ride,
          previousRefundedAmount: refundedAmount,
          loaded: true,
          open: true,
        });
      });
    } else {
      this.setState({
        popupType: INVOICE,
        selectedVehicleModel: selectedVehicleModel,
        perMinuteCharge: perMinuteCharge,
        baseCharge: baseCharge,
        selectedRide: ride,
        previousRefundedAmount: refundedAmount,
        loaded: true,
        open: true,
      });
    }
  };

  openMapPopup = async (ride) => {
    const { start_time: start, end_location, end_time, start_zone_id: zone_id, id: ride_id } = ride;
    this.setState({
      popupType: MAP,
      loaded: false,
      mapPlaces: [],
    });
    let map_zone = 0;
    let zone_boundary_points = [];
    let vehicleLocationCount = 0;
    let formattedPlaces = [];
    this.props.actions
      .getRideLocations(ride_id)
      .then(() => {
        let timeLbl = '';
        let lat = '';
        let lng = '';
        let zIndex = 0;
        let backgroundColor = '';
        let locations = Object.entries(this.props.locations);
        vehicleLocationCount = locations.length - 1;
        formattedPlaces = locations.map((place, i) => {
          if (i === 0) {
            //start location, properties saved and pushed on to end of map places array after array of places is built so it shows on top
            this.setState({ mapCenter: { lat: place[1].latitude, lng: place[1].longitude } });
            timeLbl = 'Start - O min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Green';
            // set zIndex of start marker to highest so it displays
            zIndex = vehicleLocationCount + 2;
            map_zone = zone_id;
            if (this.props.zones.length > 0) {
              this.props.zones.forEach((zone) => {
                if (zone.id === map_zone) {
                  zone.geofence.forEach((points) => {
                    const coords = {
                      lat: points[0],
                      lng: points[1],
                    };
                    zone_boundary_points.push(coords);
                  });
                }
              });
            }
          } else if (i > 0 && i <= vehicleLocationCount - 1) {
            timeLbl = getTimeInterval(start, place[1].created_date);
            timeLbl = timeLbl + ' min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Cornsilk';
            zIndex = i;
          } else if (i === vehicleLocationCount) {
            timeLbl = getTimeInterval(start, end_time);
            timeLbl = 'End - ' + timeLbl + ' min';
            lat = end_location.latitude;
            lng = end_location.longitude;
            backgroundColor = 'Red';
            zIndex = vehicleLocationCount + 1;
          }
          return {
            id: place[1].id,
            lat: lat,
            lng: lng,
            time: timeLbl,
            backgroundColor: backgroundColor,
            zindex: zIndex,
          };
        });
      })
      .then(() => this.props.actions.getFeatures(zone_id))
      .then(() => {
        if (vehicleLocationCount > 0) {
          this.setState({
            mapPlaces: formattedPlaces,
            mapZoneBoundary: zone_boundary_points,
            popupType: MAP,
            open: true,
            loaded: true,
          });
        } else {
          alert('Error: There was an error loading the Ride Map for this ride.');
          this.setState({
            loaded: true,
          });
        }
      });
  };

  openScooterPopup = (vehicle_id) => {
    this.setState({
      popupType: SCOOTER_RIDE,
      open: true,
      loaded: false,
    });
    this.props.actions
      .getVehicleRides(vehicle_id)
      .then(() => {
        this.setState({
          vehicleRidesTableData: this.props.vehicle_rides,
          popupType: SCOOTER_RIDE,
          open: true,
          loaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
        alert('Error: There was an error loading the Scooter Rides popup');
        this.setState({ loaded: true });
      });
  };

  closePopup() {
    this.setState({
      open: false,
    });
  }

  loadPopup = () => {
    const type = this.state.popupType;
    switch (type) {
      case MAP:
        // These maps end up being shared with users, so filter out the invisible restricted areas
        // that they wouldn't see in the consumer app. The map is 95% height to make room for
        // the controls
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '640px' }} />}
            mapElement={<div style={{ height: '95%' }} />}
            center={this.state.mapCenter}
            zoom={16}
            places={this.state.mapPlaces}
            parkingSpots={this.props.features.parkingSpots}
            restrictedAreas={this.props.features.restrictedAreas.filter((ra) => ra.show_on_map)}
            showParkingSpots={true}
            showPolygons={true}
            mapZoneBoundary={this.state.mapZoneBoundary}
            showUserRide={true}
            showControls={true}
          />
        );
      case SCOOTER_RIDE:
        return (
          <div className="modal">
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={closeButtonImage}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <div className="scooterRidesTable">
              <ScooterRides rides={this.state.vehicleRidesTableData} />
            </div>
          </div>
        );
      case INVOICE:
        return (
          <div className="modal">
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={closeButtonImage}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <RideDetails ride={this.state.selectedRide} restrictRefund="true" />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { rides = [], showRidesTable, ride_id } = this.state;
    const popup = this.loadPopup;
    const [ride] = rides;
    const tableHeight = ride && ride.group_rides ? ride.group_rides.length * 100 + 150 : 150;
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {popup}
        </Popup>
        <div className="page-title">Ride Lookup</div>
        <form onSubmit={this.onSubmitRide}>
          <div className="form_input_section">
            <label className="form_input_section__label" htmlFor="ride_id">
              Ride Id (Minimum of 8 characters)
            </label>
            <input
              type="text"
              name="ride_id"
              placeholder="Ride Id"
              value={ride_id}
              onChange={this.onChange}
            />
          </div>
          <div className="form_input_section">
            <input type="submit" disabled={this.state.disabled} />
            <button onClick={this.onClickReset}>Reset</button>
          </div>
        </form>
        <p style={{ color: this.state.feedbackColor }}>{this.state.feedback}</p>
        {showRidesTable && (
          <RideTable
            onClickInvoice={this.openInvoicePopup}
            onClickScooter={this.openScooterPopup}
            onClickRideMap={this.openMapPopup}
            rides={[ride]}
            tableHeight={tableHeight}
          />
        )}
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

Ride.propTypes = {
  actions: PropTypes.object,
  features: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  locations: PropTypes.array,
  rides: PropTypes.object,
  vehicle_rides: PropTypes.array,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { rides, vehicle_rides, zones } = state;
  return {
    locations: rides.locations,
    rides: rides.ride,
    features: zones.features,
    zones: zones.zones,
    vehicle_rides,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getRideById,
        clearRides,
        getRideLocations,
        getFeatures,
        getVehicleRides,
        getRefundForRide,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ride);
