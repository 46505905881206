import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import { displayDuration, penniesToDollars } from '../../util/helper';

const moment = require('moment-timezone');

export default class ScooterRides extends Component {
  render() {
    const { rides } = this.props;
    return (
      <ReactTable
        ref={(r) => {
          this.selectTable = r;
        }}
        filterable
        onFilteredChange={this.onFilteredChange}
        className="-striped -highlight users_table user_table_add_on "
        data={rides}
        showPaginationBottom={false}
        pageSize={rides.length}
        columns={[
          {
            Header: 'Zone',
            accessor: 'start_zone_name',
            maxWidth: 75,
          },
          {
            Header: 'User Id',
            accessor: 'user_id',
            width: 190,
            Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
          },
          {
            Header: 'Start (Pacific)',
            accessor: 'created_date',
            Cell: (props) => (
              <Fragment>
                {props.value &&
                  moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
              </Fragment>
            ),
            maxWidth: 175,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return moment(a).isBefore(b) ? 1 : -1;
            },
          },
          {
            Header: 'Duration',
            accessor: 'duration',
            Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
            maxWidth: 50,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return a < b ? 1 : -1;
            },
          },
          {
            Header: 'Charged',
            accessor: 'charged_amount',
            maxWidth: 70,
            className: 'currency',
            Cell: (props) => <Fragment>{penniesToDollars(props.value)}</Fragment>,
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              return a < b ? 1 : -1;
            },
          },
          {
            Header: 'Ride End Reason',
            accessor: 'ride_end_reason',
            maxWidth: 300,
          },
          {
            Header: 'Payment Status',
            accessor: 'payment_status',
            maxWidth: 70,
            Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
          },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  }
}
